import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';



const firebaseConfig = {
    apiKey: "AIzaSyBZWNSTVZZo7iWkEH9kjX1QUQ36AZbDHDo",
    authDomain: "kompreshop.firebaseapp.com",
    projectId: "kompreshop",
    storageBucket: "kompreshop.appspot.com",
    messagingSenderId: "814363258420",
    appId: "1:814363258420:web:cc670cf0857ec60e732461",
    measurementId: "G-2MQQJJ6HSJ"
  };




  // Initialize Firebase
firebase.initializeApp(firebaseConfig);
  
//const db = firebase.firestore()
const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();


export { firebase, auth, db, storage };