<template>
  <div class="relative bg-gray-50 pt-16 pb-20 px-4 sm:px-6 lg:pt-24 lg:pb-28 lg:px-8">
    
    <div class="absolute inset-0">
      <div class="bg-white h-1/3 sm:h-2/3" />
    </div>
    
    <div class="relative max-w-7xl mx-auto">
      <a href="https://www.kompre.pl/"><img src="../assets/kompreLogo-min.png" class="mb-8" alt="Laptopy poleasingowe oraz komputery używane. Sprawdź sklep komputerowy Kompre. Najlepsze laptopy uzywane."></a>
      <div class="text-center">
        <h2 class="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">Blog <span class="kompreRed">Kompre.</span></h2>
        <p class="mt-3 max-w-2xl mx-auto text-xl text-gray-500 sm:mt-4">Wejdź w świat komputerów poleasingowych, laptopów używanych oraz sprzętów powystawowych klasy Premium.</p>
      </div>
      <div class="mt-12 max-w-lg mx-auto grid gap-5 lg:grid-cols-3 lg:max-w-none">
        <div v-for="post in posts" :key="post.title" class="flex flex-col rounded-lg shadow-lg overflow-hidden hover:opacity-70 transition-all">
          <router-link :to="`/post/${post.id}`">
            <div class="flex-shrink-0">
              <img class="h-48 w-full object-cover" :src="post.image" alt="" />
            </div>
            <div class="flex-1 bg-white p-6 flex flex-col justify-between">
              <div class="flex-1">
                <p class="text-sm font-medium text-red-600">
                  POST
                </p>
                <div class="block mt-2">
                  <p class="text-xl font-semibold text-gray-900">
                    {{ post.title }}
                  </p>
                  <p class="mt-3 text-base text-gray-500">
                    {{ post.shortText }}
                  </p>
                </div>
                
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {db} from '../firebase/config.js';

export default {
  name: 'Home',
  data() {
    return {
      posts: []
    }
  },
  components: {
  },
  methods: {
    async getPosts()
    {
      this.posts = [];
      try {
        const result = await db.collection("BlogPosts").where("active","==",true).orderBy('createdDate','desc').get();
        for(let i=0;i<result.docs.length;i++)
        {
          this.posts.push(result.docs[i].data());
        }
        console.log(this.posts);
      }
      catch(error)
      {
        console.log(error);
      }
    }
  },
  created()
  {
    this.getPosts();
  }
}
</script>

<style lang="scss">
  .kompreRed
  {
    color: #e03024;
  }

  .kompreGray
  {
    color: #604F4F;
  }

</style>
